import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('draggable',_vm._b({staticClass:"width-max-width-custom",attrs:{"list":_vm.perguntas,"handle":"#handle"},on:{"end":_vm.updatePositionOfQuestions}},'draggable',_vm.dragOpcoes,false),_vm._l((_vm.perguntas),function(item,index){return _c(VCard,{key:item.id,staticClass:"mx-3 my-3 pa-1 white",attrs:{"elevation":"2","outlined":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between h-full"},[(_vm.validateBusinessRules)?_c(VBtn,{staticClass:"grabbable h-full px-0",attrs:{"id":"handle","min-width":"15px","elevation":"0"}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-drag-vertical")])],1):_vm._e(),_c(VCard,{staticClass:"px-2 ml-1 ml-sm-2 primary",attrs:{"elevation":"0","rounded":"2"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"text-center pa-0 ma-0 white--text"},'p',attrs,false),on),[_vm._v(" "+_vm._s((item.posicao = index + 1))+" ")])]}}],null,true)},[_c('span',[_vm._v("Posição")])])],1),(item.tipo)?_c('div',{staticClass:"ml-2 ml-sm-4"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.iconsOfTypesQuestions[item.tipo].icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.iconsOfTypesQuestions[item.tipo].text))])])],1):_vm._e()],1)]),_c(VCol,{attrs:{"cols":"12","sm":item.titulo_pergunta_validadora ? '6' : '9'}},[_c('div',{staticClass:"d-flex flex-row align-center justify-start h-full ml-sm-4"},[_c('p',{staticClass:"text-justify black--text pa-0 ma-0 text-truncate"},[_vm._v(" "+_vm._s(item.titulo)+" ")])])]),(item.titulo_pergunta_validadora)?_c(VCol,{attrs:{"cols":"6","sm":"3"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({attrs:{"label":"","outlined":"","color":"primary"}},'v-chip',attrs,false),on),[_c(VIcon,[_vm._v("mdi-swap-horizontal")]),_c('span',{staticClass:"ml-1 text-truncate"},[_vm._v(" "+_vm._s(item.titulo_pergunta_validadora)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" A pergunta "),_c('strong',[_vm._v(_vm._s(item.titulo_pergunta_validadora))]),_vm._v(" invalida está. ")])])],1):_vm._e(),_c(VCol,{attrs:{"cols":"6","sm":"1"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end h-full"},[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{staticClass:"actions",on:{"click":function($event){return _vm.toggleModalLateral(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-eye")])],1),_c(VListItemTitle,[_vm._v(" Visualizar ")])],1),(_vm.validateBusinessRules)?_c(VListItem,{staticClass:"actions",on:{"click":function($event){return _vm.edit(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VListItemTitle,[_vm._v(" Editar ")])],1):_vm._e(),(
                    _vm.validateBusinessRules &&
                    (_vm.currentPositionInSteps.subcategory === 'FAV' ||
                      _vm.currentPositionInSteps.type === 'FE')
                  )?_c(VListItem,{staticClass:"actions",on:{"click":function($event){return _vm.duplicate(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-content-copy")])],1),_c(VListItemTitle,[_vm._v(" Duplicar ")])],1):_vm._e(),(_vm.validateBusinessRules)?_c(VListItem,{staticClass:"actions",on:{"click":function($event){return _vm.remover(item, true)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-trash-can-outline")])],1),_c(VListItemTitle,[_vm._v(" Excluir ")])],1):_vm._e()],1)],1)],1)])],1)],1)}),1),(_vm.dialogDelete)?_c('ModalDelete',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"modalDelete/titleModal"},[_vm._v("Excluir o registro?")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. "),_c('br'),(_vm.isRemoveQuestionInvalidator)?_c('span',[_c('br'),_c('span',{staticClass:"error--text"},[_vm._v("AVISO:")]),_vm._v(" Essa pergunta invalida outra, caso escolha remover a mesma estará altomaticamente removendo a invalidação. "),_c('br')]):_vm._e(),_c('br'),_vm._v(" Você realmente deseja excluir essa pergunta? ")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"color":"error","loading":_vm.loading.loadingButtonDelete},on:{"click":function($event){return _vm.remover()}}},[_vm._v("Excluir")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1)],2):_vm._e(),_c('ModalLateral',{attrs:{"show":_vm.modalLateral.show,"hide":_vm.toggleModalLateral,"title":_vm.modalLateral.title,"headers":_vm.headerModalLateral,"item":_vm.modalLateral.item},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
                  var item = ref.item;
return [(item.tipo == 'text')?_c('span',[_vm._v("Texto aberto")]):(item.tipo == 'radio')?_c('span',[_vm._v("Opção única")]):_c('span',[_vm._v("Múltipla escolha")])]}},{key:"item.obrigatoriedade",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.obrigatoriedade == 1 ? "Sim" : "Não"))])]}},{key:"item.pergunta_invalida",fn:function(ref){
                  var item = ref.item;
return [(
          item &&
          item.subcategoria == 'FAV' &&
          item.pergunta_invalida != null &&
          _vm.getQuestionRelatedToModalLateral != undefined
        )?_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Título:")]),_vm._v(" "+_vm._s(_vm.getQuestionRelatedToModalLateral.titulo)+" ")]):_c(VChip,{staticClass:"my-1 white--text align-center",attrs:{"color":"primary"}},[_vm._v(" Não invalida outra pergunta. ")])]}},{key:"item.opcoes",fn:function(ref){
        var item = ref.item;
return [(item.opcoes && item.opcoes.length > 0)?_c('div',_vm._l((item.opcoes),function(items,i){return _c('div',{key:items.id},[_c('span',{staticClass:"my-1"},[_c('strong',[_vm._v(_vm._s(i + 1)+".")]),_vm._v(" "+_vm._s(items.descricao))])])}),0):(item.subcategoria == 'FAV')?_c('span',[_vm._v(" As opções desta pergunta são de acordo com a escala escolhida. ")]):_c(VChip,{staticClass:"my-1 white--text align-center",attrs:{"color":"primary"}},[_vm._v(" Esta pergunta não tem opções. ")])]}},{key:"item.limite_de_opcoes",fn:function(ref){
        var item = ref.item;
return [(item.tipo === 'checkbox' && item.limite_de_opcoes)?_c('div',[_c('span',[_vm._v(_vm._s(item.limite_de_opcoes))])]):_c(VChip,{staticClass:"my-1 white--text align-center",attrs:{"color":"primary"}},[_vm._v(" Sem limite de opções. ")])]}},{key:"item.resposta_invertida",fn:function(ref){
        var item = ref.item;
return [(item.resposta_invertida == true)?_c('span',[_vm._v("Possuí resposta invertida ")]):_c('span',[_vm._v(" Não possuí resposta invertida ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status == "A" ? "Ativo" : "Inativo"))])]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.created_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.created_at)))])]}}],null,true)}),_c('DialogLoader',{attrs:{"loading":_vm.loading.loadingDialogLoader}},[_c('template',{slot:"content-description"},[_c('span',[_vm._v(_vm._s(_vm.dialogLoaderDescription))])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }