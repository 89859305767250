<template>
  <section>
    <draggable
      v-bind="dragOpcoes"
      :list="perguntas"
      handle="#handle"
      class="width-max-width-custom"
      @end="updatePositionOfQuestions"
    >
      <v-card
        elevation="2"
        v-for="(item, index) in perguntas"
        outlined
        :key="item.id"
        class="mx-3 my-3 pa-1 white"
      >
        <v-row>
          <v-col cols="12" sm="2">
            <div
              class="d-flex flex-row align-center justify-space-between h-full"
            >
              <!-- Drag -->
              <v-btn
                v-if="validateBusinessRules"
                id="handle"
                min-width="15px"
                elevation="0"
                class="grabbable h-full px-0"
              >
                <v-icon color="secondary">mdi-drag-vertical</v-icon>
              </v-btn>
              <!-- Posição -->
              <v-card
                elevation="0"
                rounded="2"
                class="px-2 ml-1 ml-sm-2 primary"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      class="text-center pa-0 ma-0 white--text"
                    >
                      {{ (item.posicao = index + 1) }}
                    </p>
                  </template>
                  <span>Posição</span>
                </v-tooltip>
              </v-card>
              <!-- Icone -->
              <div class="ml-2 ml-sm-4" v-if="item.tipo">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">
                      {{ iconsOfTypesQuestions[item.tipo].icon }}
                    </v-icon>
                  </template>
                  <span>{{ iconsOfTypesQuestions[item.tipo].text }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" :sm="item.titulo_pergunta_validadora ? '6' : '9'">
            <!-- titulo -->
            <div
              class="d-flex flex-row align-center justify-start h-full ml-sm-4"
            >
              <p class="text-justify black--text pa-0 ma-0 text-truncate">
                {{ item.titulo }}
              </p>
            </div>
          </v-col>
          <v-col v-if="item.titulo_pergunta_validadora" cols="6" sm="3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" label outlined color="primary">
                  <v-icon>mdi-swap-horizontal</v-icon>
                  <span class="ml-1 text-truncate">
                    {{ item.titulo_pergunta_validadora }}
                  </span>
                </v-chip>
              </template>
              <span>
                A pergunta
                <strong>{{ item.titulo_pergunta_validadora }}</strong> invalida
                está.
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" sm="1">
            <!-- Opções -->
            <div class="d-flex flex-row align-center justify-end h-full">
              <v-menu bottom left>
                <!-- Botão menu 3 pontos -->
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <!-- Opções Menu -->
                <v-list dense>
                  <!-- Visualizar -->
                  <v-list-item
                    class="actions"
                    @click="toggleModalLateral(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Visualizar </v-list-item-title>
                  </v-list-item>
                  <!-- Editar -->
                  <v-list-item
                    v-if="validateBusinessRules"
                    class="actions"
                    @click="edit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Editar </v-list-item-title>
                  </v-list-item>
                  <!-- Duplicar -->
                  <v-list-item
                    class="actions"
                    v-if="
                      validateBusinessRules &&
                      (currentPositionInSteps.subcategory === 'FAV' ||
                        currentPositionInSteps.type === 'FE')
                    "
                    @click="duplicate(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Duplicar </v-list-item-title>
                  </v-list-item>
                  <!-- Deletar -->
                  <v-list-item
                    v-if="validateBusinessRules"
                    class="actions"
                    @click="remover(item, true)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Excluir </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </draggable>
    <!--------------- MODAL DE DELETE --------------------------------------------- -->
    <ModalDelete v-if="dialogDelete" :dialogDelete.sync="dialogDelete">
      <template slot="modalDelete/titleModal">Excluir o registro?</template>
      <template slot="modalDelete/contentModal">
        <p style="text-align: justify">
          Atenção! Você está prestes a realizar uma ação que não pode ser
          desfeita.
          <br />
          <span v-if="isRemoveQuestionInvalidator">
            <br />
            <span class="error--text">AVISO:</span> Essa pergunta invalida
            outra, caso escolha remover a mesma estará altomaticamente removendo
            a invalidação.
            <br />
          </span>
          <br />
          Você realmente deseja excluir essa pergunta?
        </p>
      </template>
      <template slot="modalDelete/actionsModal">
        <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
        <v-btn
          color="error"
          :loading="loading.loadingButtonDelete"
          @click="remover()"
          >Excluir</v-btn
        >
        <v-spacer class="d-none d-md-block"></v-spacer>
      </template>
    </ModalDelete>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.tipo`]="{ item }">
        <span v-if="item.tipo == 'text'">Texto aberto</span>
        <span v-else-if="item.tipo == 'radio'">Opção única</span>
        <span v-else>Múltipla escolha</span>
      </template>
      <template v-slot:[`item.obrigatoriedade`]="{ item }">
        <span>{{ item.obrigatoriedade == 1 ? "Sim" : "Não" }}</span>
      </template>
      <template v-slot:[`item.pergunta_invalida`]="{ item }">
        <div
          v-if="
            item &&
            item.subcategoria == 'FAV' &&
            item.pergunta_invalida != null &&
            getQuestionRelatedToModalLateral != undefined
          "
        >
          <span class="font-weight-bold">Título:</span>
          {{ getQuestionRelatedToModalLateral.titulo }}
        </div>
        <v-chip v-else class="my-1 white--text align-center" color="primary">
          Não invalida outra pergunta.
        </v-chip>
      </template>
      <template v-slot:[`item.opcoes`]="{ item }">
        <div v-if="item.opcoes && item.opcoes.length > 0">
          <div v-for="(items, i) in item.opcoes" :key="items.id">
            <span class="my-1"
              ><strong>{{ i + 1 }}.</strong> {{ items.descricao }}</span
            >
          </div>
        </div>
        <span v-else-if="item.subcategoria == 'FAV'">
          As opções desta pergunta são de acordo com a escala escolhida.
        </span>
        <v-chip v-else class="my-1 white--text align-center" color="primary">
          Esta pergunta não tem opções.
        </v-chip>
      </template>
      <template v-slot:[`item.limite_de_opcoes`]="{ item }">
        <div v-if="item.tipo === 'checkbox' && item.limite_de_opcoes">
          <span>{{ item.limite_de_opcoes }}</span>
        </div>
        <v-chip v-else class="my-1 white--text align-center" color="primary">
          Sem limite de opções.
        </v-chip>
      </template>
      <template v-slot:[`item.resposta_invertida`]="{ item }">
        <span v-if="item.resposta_invertida == true"
          >Possuí resposta invertida
        </span>
        <span v-else> Não possuí resposta invertida </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ item.status == "A" ? "Ativo" : "Inativo" }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
    </ModalLateral>
    <DialogLoader :loading="loading.loadingDialogLoader">
      <template slot="content-description">
        <span>{{ dialogLoaderDescription }}</span>
      </template>
    </DialogLoader>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import ModalDelete from "@/components/ModalDelete.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import DialogLoader from "./DialogLoader.vue";

export default {
  name: "ItemListaPerguntaComponent",
  components: {
    draggable,
    ModalDelete,
    ModalLateral,
    DialogLoader,
  },
  props: ["validateBusinessRules"],
  data: () => ({
    menuPergunta: [
      { title: "Editar", icon: "edit" },
      {
        title: "Duplicar",
        icon: "mdi-checkbox-multiple-blank",
        action: "edit",
      },
      { title: "Excluir", icon: "mdi-trash-can-outline", action: "edit" },
    ],
    loading: {
      loadingButtonDelete: false,
      loadingDialogLoader: false,
    },
    isRemoveQuestionInvalidator: false,
    dialogLoaderDescription: "Carregando...",

    // Icones relacionados a cada tipo de pergunta
    iconsOfTypesQuestions: {
      text: { icon: "mdi-text", text: "Texto" },
      radio: { icon: "mdi-radiobox-marked", text: "Opção única" },
      checkbox: {
        icon: "mdi-checkbox-multiple-marked",
        text: "Múltipla escolha",
      },
    },

    // Variaveis do modal lateral
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    headerModalLateral: [
      { text: "Tipo", value: "tipo" },
      { text: "Descrição", value: "descricao" },
      { text: "Limite de opção", value: "limite_de_opcoes" },
      { text: "Obrigatoriedade", value: "obrigatoriedade" },
      { text: "Pergunta invalidada", value: "pergunta_invalida" },
      { text: "Resposta invertida", value: "resposta_invertida" },
      { text: "Opções", value: "opcoes" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],

    // Variaveis do modal delete
    item: [],
    dialogDelete: false,
  }),
  methods: {
    async toggleModalLateral(item = Object) {
      if (this.modalLateral.show) return (this.modalLateral.show = false);

      this.dialogLoaderDescription = "Carregando pergunta para visualização.";

      const question = await this.fetchAllDataPergunta(item);

      this.modalLateral.title = question.titulo;
      this.modalLateral.item = question;
      this.modalLateral.show = true;
    },
    updatePositionOfQuestions: function (evt) {
      let questions =
        evt.oldIndex > evt.newIndex
          ? this.perguntas.slice(evt.newIndex, evt.oldIndex + 1)
          : this.perguntas.slice(evt.oldIndex, evt.newIndex + 1);

      if (questions.length < 2) return;

      this.dialogLoaderDescription = "Atualizando as posições das perguntas.";
      this.loading.loadingDialogLoader = true;

      const questionsFiltred = this.clearDatas(questions);

      this.$store
        .dispatch("pcoPerguntas/updatePositionQuestions", {
          perguntas: questionsFiltred,
        })
        .then((response) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Posições atualizadas com sucesso.",
            cor: "green",
          });
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Houve um erro em atualizar as posições",
            cor: "red",
          });
        })
        .finally(() => {
          this.loading.loadingDialogLoader = false;
          this.dialogLoaderDescription = "Carregando...";
        });
    },
    /**
     * @description Pega o array de perguntas e filtra retornando o array somente com id e posição
     *
     * @param Array datas Array de objetos de perguntas
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    clearDatas(datas = Array) {
      let newArrayPerguntas = [];

      datas.forEach((el) => {
        newArrayPerguntas.push({ id: el.id, posicao: el.posicao });
      });

      return newArrayPerguntas;
    },
    async edit(item = Object) {
      this.dialogLoaderDescription = "Carregando pergunta para edição.";

      const question = await this.fetchAllDataPergunta(item);

      Object.assign(this.pergunta, question);

      this.dialogModalPergunta = true;
    },
    /**
     * @description função responsável por buscar todos os dados da pergunta e preencher reativamente a State pergunta
     * - Ao ser executado a função o loading será executado como forma de feedback para o usuário
     *
     * @param Object item Objeto da pergunta
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async fetchAllDataPergunta(item = Object) {
      this.loading.loadingDialogLoader = true;

      return await this.$store
        .dispatch("pcoPerguntas/fetchAllDataPergunta", item.uuid)
        .then((result) => {
          return result;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Houve um erro ao buscar pelos dados da pergunta.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loading.loadingDialogLoader = false;
        });
    },
    duplicate(item = Object) {
      this.loading.loadingDialogLoader = true;
      this.dialogLoaderDescription = "Duplicando pergunta.";
      item.titulo = `Copia ${item.titulo}`;
      item.code_front = Math.floor(Date.now() / 1000);
      const data = {
        uuid: item.uuid,
      };

      this.$store
        .dispatch("pcoPerguntas/duplicate", data)
        .then((response) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Pergunta duplicada com sucesso",
            cor: "green",
          });

          this.perguntas.push(response);
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao duplicar a pergunta. tente novamente mais tarde.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loading.loadingDialogLoader = false;
          this.dialogLoaderDescription = "Carregando...";
        });
    },
    remover(item = Object, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
        this.isRemoveQuestionInvalidator = item.pergunta_invalida
          ? true
          : false;
      } else {
        this.loading.loadingButtonDelete = true;

        const indexPergunta = this.perguntas.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("pcoPerguntas/remove", this.item.uuid)
          .then((response) => {
            if (response.data) {
              this.validateRelationshipsBetweenQuestionsIsNull();

              this.perguntas.splice(indexPergunta, 1);

              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: "Pergunta removido com sucesso!",
                cor: "green",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Não foi possivel remover o pergunta.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loading.loadingButtonDelete = false;
            this.dialogDelete = false;
            this.item = {};
          });
      }
    },
    /**
     * @description Valida se o objeto em questão que está a ser excluído está desvinculando relação com outra pergunta. Caso estiver será limpo o relacionamento.
     *
     * @depedency
     * - item (Data)
     * - perguntas (Computed)
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateRelationshipsBetweenQuestionsIsNull() {
      if (
        this.isRemoveQuestionInvalidator &&
        this.item.pergunta_invalida.pergunta_validadora
      ) {
        const indexQuestionRelated = this.perguntas.findIndex(
          (el) =>
            el.code_front == this.item.pergunta_invalida.pergunta_validadora
        );

        this.perguntas[indexQuestionRelated].pergunta_invalida = null;
      }

      return this;
    },
  },
  computed: {
    dragOpcoes() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    currentPositionInSteps: {
      get() {
        return this.$store.getters["pcoModelos/getCurrentPositionInSteps"];
      },
    },
    perguntas: {
      get() {
        return this.currentPositionInSteps.subcategory
          ? this.$store.getters["pcoPerguntas/getAllPerguntas"][
              this.currentPositionInSteps.type
            ][this.currentPositionInSteps.subcategory]
          : this.$store.getters["pcoPerguntas/getAllPerguntas"][
              this.currentPositionInSteps.type
            ];
      },
    },
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoPerguntas/setPergunta", value);
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    getQuestionRelatedToModalLateral() {
      if (
        this.modalLateral.item &&
        this.modalLateral.item.pergunta_invalida == null
      )
        return "";

      const questionRelated = this.perguntas.find(
        (el) =>
          el.code_front ==
          this.modalLateral.item.pergunta_invalida.pergunta_validadora
      );

      return questionRelated;
    },
  },
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.width-max-width-custom {
  width: 100%;
}

.actions:hover {
  background-color: lightgrey !important;
}
</style>
