import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VRow,[_c(VCol,{staticClass:"d-flex pb-5",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mr-auto",attrs:{"color":"primary","outlined":"","to":{ name: 'pco-modelos' }}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" mdi-arrow-left-thin ")]),_c('span',[_vm._v("voltar")])],1)],1)],1),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-2"},[_c('h1',[_vm._v(" "+_vm._s(_vm.modelo.nome ? _vm.modelo.nome : _vm.action == "editar" ? "Carregando modelo..." : "Modelo sem nome")+" ")]),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VCard,_vm._g(_vm._b({staticClass:"ml-3 pa-1",attrs:{"color":_vm.statusOfAction[_vm.showStatusOfAction].color,"dark":""}},'v-card',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.statusOfAction[_vm.showStatusOfAction].icon))])],1)]}}])},[_c('span',[_vm._v("Modo de "+_vm._s(_vm.statusOfAction[_vm.showStatusOfAction].text))])])],1),_c(VDivider),_c('br'),_c(VStepper,{attrs:{"alt-labels":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_vm._l((_vm.steps),function(itemStep){return [(
            (itemStep.id > 1 && itemStep.id - 1 !== _vm.step) ||
            (itemStep.id > 1 && itemStep.id - 1 == _vm.step)
          )?_c(VDivider,{key:itemStep.id}):_vm._e(),_c(VStepperStep,{key:((itemStep.id) + "-step"),attrs:{"complete":_vm.step > itemStep.id,"step":itemStep.id}},[_vm._v(" "+_vm._s(itemStep.title)+" ")])]})],2),_c(VContainer,[_c('div',{staticClass:"d-flex flex-column"},[_c('h2',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Descrição")]),_c('p',{staticClass:"text-justify text-body-2",domProps:{"innerHTML":_vm._s(_vm.currentPositionInSteps.description)}})]),(_vm.currentPositionInSteps && _vm.currentPositionInSteps.form)?_c('ListaPerguntaComponent',{attrs:{"validateBusinessRules":_vm.validateBusinessRules}}):_c(_vm.currentPositionInSteps.component,{tag:"component",attrs:{"validateBusinessRules":_vm.validateBusinessRules}}),_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-space-between mt-10"},[(_vm.step == 1)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Fechar")]):_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.backStep()}}},[_vm._v("Voltar")]),(
            _vm.step < _vm.steps.length && (_vm.action == 'editar' || _vm.action == 'criar')
          )?_c(VBtn,{attrs:{"loading":_vm.loadings.loadingBasicInformations,"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" "+_vm._s(_vm.currentPositionInSteps.role.saveModelByProcess ? "Salvar e Continuar" : "Continuar")+" ")]):(_vm.step < _vm.steps.length)?_c(VBtn,{attrs:{"loading":_vm.loadings.loadingBasicInformations,"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" "+_vm._s("Continuar")+" ")]):_c(VBtn,{attrs:{"color":_vm.action == 'visualizar' ? 'primary' : 'success'},on:{"click":function($event){return _vm.redirect('pco-modelos')}}},[_vm._v(" "+_vm._s(_vm.action == "visualizar" ? "Fechar" : "Finalizar")+" ")])],1)],1)],1),_c('GenericStructureForm')],1)}
var staticRenderFns = []

export { render, staticRenderFns }