<template>
  <section>
    <component :is="currentPositionInSteps.form" />
  </section>
</template>

<script>
export default {
  name: "GenericStructureForm",
  inject: ["$validator"],
  data() {
    return {
      currentForm: {
        FE: {
          validate: "form-fe.*",
        },
        FI: {
          FAV: {
            validate: "form-fav.*",
          },
          BEN: {
            validate: "form-ben.*",
          },
          DES: {
            validate: "form-des.*",
          },
          MOT: {
            validate: "form-mot.*",
          },
        },
      },
      isEdit: false,
    };
  },
  methods: {
    save() {
      this.isEdit = this.pergunta.id && this.pergunta.uuid ? true : false;

      this.implementDatasDefaultInQuestion()
        .validateIfQuestionTextTypeHasOptions()
        .validateIfPerguntaInvalidaIsNull()
        .validateIfQuestionIsValidadora();

      this.processOfSave = true;

      let url = this.isEdit
        ? `/pco-perguntas/${this.pergunta.uuid}`
        : "/pco-perguntas";
      let method = this.isEdit ? "PUT" : "POST";
      let sucesso = this.isEdit
        ? "Pergunta atualizada com sucesso."
        : "Pergunta cadastrada com sucesso.";
      let erro = this.isEdit
        ? "Erro ao atualizar pergunta."
        : "Erro ao salvar pergunta.";
      let perguntaItem = this.pergunta;

      perguntaItem._method = method;
      perguntaItem.url = url;

      this.$store
        .dispatch("pcoPerguntas/save", perguntaItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: sucesso,
              cor: "green",
            });

            let dataInvalidQuestion = {};

            if (this.isEdit) {
              const index = this.perguntas.findIndex(
                (el) => el.id == perguntaItem.id
              );

              dataInvalidQuestion = perguntaItem;
              this.perguntas.splice(index, 1, perguntaItem);
            } else {
              dataInvalidQuestion = response;
              this.perguntas.push(response);
            }

            this.saveTitleQuestionInvalidQuestion(
              dataInvalidQuestion
            ).setPerguntaInvalidaInQuestion(dataInvalidQuestion);
          }
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: erro,
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingModalPergunta = false;
          this.processOfSave = false;
          this.dialog = false;

          this.$store.dispatch("pcoPerguntas/resetPergunta");
        });
    },
    /**
     * @description Atualiza o titulo da pergunta validadora na pergunta invalida
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    saveTitleQuestionInvalidQuestion(item = Object) {
      let index = -1;

      if (
        item.pergunta_invalida &&
        item.pergunta_invalida.pergunta_validadora != undefined
      ) {
        index = this.perguntas.findIndex(
          (el) => el.code_front == item.pergunta_invalida.pergunta_validadora
        );

        if (index != -1) {
          item.titulo_pergunta_validadora = this.perguntas[index].titulo;
        }
      } else {
        index = this.perguntas.findIndex(
          (el) =>
            el.pergunta_invalida &&
            el.pergunta_invalida.pergunta_validadora == item.code_front
        );

        if (index != -1) {
          this.perguntas[index].titulo_pergunta_validadora = item.titulo;
        }
      }

      return this;
    },
    /**
     * @description Seta o pergunta_invalida na pergunta invalidadora
     *
     * @param item Object
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    setPerguntaInvalidaInQuestion(item = Object) {
      if (
        item.pergunta_invalida &&
        item.pergunta_invalida.pergunta_validadora != undefined
      ) {
        let index = -1;

        index = this.perguntas.findIndex(
          (el) => el.code_front == item.pergunta_invalida.pergunta_validadora
        );

        if (index != -1) {
          this.perguntas[index].pergunta_invalida = item.pergunta_invalida;
          this.perguntas[index].titulo_pergunta_validadora = null;
        }
      }

      return this;
    },
    /**
     * @description Implementa dados padrão na pergunta antes do salvamento da mesma.
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    implementDatasDefaultInQuestion() {
      this.pergunta.id_modelo = this.modelo.id;
      this.isEdit ? null : (this.pergunta.posicao = this.perguntas.length + 1);

      return this;
    },
    /**
     * @description Valida de a pergunta é do tipo texto, caso for será validado se tem opções registradas, caso tenha será excluida
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateIfQuestionTextTypeHasOptions() {
      if (this.pergunta.tipo == "text" && this.pergunta.opcoes.length > 0) {
        this.pergunta.opcoes.forEach((element, index) => {
          if (element.uuid != undefined)
            this.$store.dispatch("pcoOpcoes/remove", element.uuid);

          this.pergunta.opcoes.splice(index, 1);
        });
      }

      return this;
    },
    /**
     * @description Valida se pergunta_invalida ou pergunta_validadora está null, caso tiver a pergunta_validadora será deletada do objeto.
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateIfPerguntaInvalidaIsNull() {
      if (
        this.pergunta.pergunta_invalida == null ||
        this.pergunta.pergunta_invalida.pergunta_validadora == null
      ) {
        delete this.pergunta.pergunta_invalida;
      }

      return this;
    },
    /**
     * @description Valida se a pergunta em questão é a pergunta validadora, caso for será removido a propriedade pergunta_invalida
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateIfQuestionIsValidadora() {
      if (
        this.pergunta.pergunta_invalida &&
        this.pergunta.pergunta_invalida.pergunta_validadora ==
          this.pergunta.code_front
      ) {
        delete this.pergunta.pergunta_invalida;
      }

      return this;
    },
  },
  computed: {
    currentPositionInSteps: {
      get() {
        return this.$store.getters["pcoModelos/getCurrentPositionInSteps"];
      },
    },
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
    },
    perguntas: {
      get() {
        return this.currentPositionInSteps.subcategory
          ? this.$store.getters["pcoPerguntas/getAllPerguntas"][
              this.currentPositionInSteps.type
            ][this.currentPositionInSteps.subcategory]
          : this.$store.getters["pcoPerguntas/getAllPerguntas"][
              this.currentPositionInSteps.type
            ];
      },
    },
    modelo: {
      get() {
        return this.$store.getters["pcoModelos/getModelo"];
      },
    },
    clickedOnSave: {
      get() {
        return this.$store.getters["pcoModelos/getClickedOnSave"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setClickedOnSave", value);
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
    dialog: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    processOfSave: {
      get() {
        return this.$store.getters["pcoModelos/getProcessOfSave"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setProcessOfSave", value);
      },
    },
  },
  watch: {
    clickedOnSave(newValue, oldValue) {
      if (newValue === true) {
        this.save();

        this.clickedOnSave = oldValue;
      }
    },
  },
};
</script>

<style scoped>
.flex-salvar-e-fechar {
  display: flex;
  justify-content: flex-end;
  margin-right: 172px;
}
</style>
