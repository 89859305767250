<template>
  <section>
    <v-row>
      <v-col class="d-flex pb-5" cols="12">
        <v-btn
          color="primary"
          outlined
          class="mr-auto"
          :to="{ name: 'pco-modelos' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            mdi-arrow-left-thin
          </v-icon>
          <span>voltar</span>
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex flex-row align-center justify-space-between mb-2">
      <h1>
        {{
          modelo.nome
            ? modelo.nome
            : action == "editar"
            ? "Carregando modelo..."
            : "Modelo sem nome"
        }}
      </h1>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-card
            v-bind="attrs"
            v-on="on"
            class="ml-3 pa-1"
            :color="statusOfAction[showStatusOfAction].color"
            dark
          >
            <v-icon color="white">{{
              statusOfAction[showStatusOfAction].icon
            }}</v-icon>
          </v-card>
        </template>
        <span>Modo de {{ statusOfAction[showStatusOfAction].text }}</span>
      </v-tooltip>
    </div>
    <v-divider />
    <br />
    <v-stepper v-model="step" alt-labels>
      <v-stepper-header>
        <template v-for="itemStep in steps">
          <v-divider
            v-if="
              (itemStep.id > 1 && itemStep.id - 1 !== step) ||
              (itemStep.id > 1 && itemStep.id - 1 == step)
            "
            :key="itemStep.id"
          ></v-divider>

          <v-stepper-step
            :key="`${itemStep.id}-step`"
            :complete="step > itemStep.id"
            :step="itemStep.id"
          >
            {{ itemStep.title }}
          </v-stepper-step>
        </template>
      </v-stepper-header>

      <v-container>
        <div class="d-flex flex-column">
          <h2 class="text-h6 font-weight-bold">Descrição</h2>
          <p
            class="text-justify text-body-2"
            v-html="currentPositionInSteps.description"
          ></p>
        </div>

        <ListaPerguntaComponent
          v-if="currentPositionInSteps && currentPositionInSteps.form"
          :validateBusinessRules="validateBusinessRules"
        />
        <component
          v-else
          :validateBusinessRules="validateBusinessRules"
          :is="currentPositionInSteps.component"
        />

        <div class="d-flex flex-column flex-sm-row justify-space-between mt-10">
          <v-btn v-if="step == 1" text @click="$router.go(-1)">Fechar</v-btn>
          <v-btn v-else text @click="backStep()">Voltar</v-btn>

          <v-btn
            v-if="
              step < steps.length && (action == 'editar' || action == 'criar')
            "
            :loading="loadings.loadingBasicInformations"
            color="primary"
            @click="nextStep()"
          >
            {{
              currentPositionInSteps.role.saveModelByProcess
                ? "Salvar e Continuar"
                : "Continuar"
            }}
          </v-btn>
          <v-btn
            v-else-if="step < steps.length"
            :loading="loadings.loadingBasicInformations"
            color="primary"
            @click="nextStep()"
          >
            {{ "Continuar" }}
          </v-btn>
          <v-btn
            v-else
            :color="action == 'visualizar' ? 'primary' : 'success'"
            @click="redirect('pco-modelos')"
          >
            {{ action == "visualizar" ? "Fechar" : "Finalizar" }}
          </v-btn>
        </div>
      </v-container>
    </v-stepper>

    <!-- <ModalPergunta /> -->
    <GenericStructureForm />
  </section>
</template>

<script>
import ListaPerguntaComponent from "../components/ListaPerguntaComponent.vue";
// import ModalPergunta from "../components/ModalPergunta.vue";
import GenericStructureForm from "../components/formularios/GenericStructureForm.vue";

export default {
  name: "FormularioModelosView",
  inject: ["$validator"],
  components: {
    ListaPerguntaComponent,
    // ModalPergunta,
    GenericStructureForm,
  },
  props: ["uuid", "action"],
  data: () => ({
    statusOfAction: {
      criar: {
        icon: "mdi-plus",
        text: "criação",
        color: "success",
      },
      visualizar: {
        icon: "mdi-eye",
        text: "visualização",
        color: "secondary",
      },
      editar: {
        icon: "mdi-pencil",
        text: "edição",
        color: "accent",
      },
    },
  }),
  created() {
    this.fetchDatasToForms();
  },
  /**
   * @description Bloquea o usuário de entrar na página caso esteja acessando a página de modo inesperado
   *
   * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
   */
  beforeRouteEnter(to, from, next) {
    if (to.params.action == "criar") {
      return next();
    }

    if (to.params.action == "visualizar" || to.params.action == "editar") {
      if (to.params.uuid == undefined) {
        return next(from.path);
      }

      return next();
    }

    return next(from.path);
  },
  computed: {
    modelo: {
      get() {
        return this.$store.getters["pcoModelos/getModelo"];
      },
    },
    step: {
      get() {
        return this.$store.getters["pcoModelos/getStep"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setStep", value);
      },
    },
    steps: {
      get() {
        return this.$store.getters["pcoModelos/getSteps"];
      },
    },
    user: {
      get() {
        return this.$store.getters["auth/getUsuario"];
      },
    },
    currentPositionInSteps: {
      get() {
        return this.$store.getters["pcoModelos/getCurrentPositionInSteps"];
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
    /**
     * @description Está função tem como objetivo validar por meio das regras de negócio bloquear ou não o usuário de fazer determinada ação.
     * - Caso seja retornado True estará permitido a edição ou criação.
     * - Caso seja retornado False estará bloqueando o sistema para só visualização
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateBusinessRules() {
      const routeParamAction = this.action;

      if (routeParamAction == "criar") {
        return true;
      }

      if (routeParamAction == "editar") {
        if (
          this.user.role == "Administrador" ||
          this.user.role == "Desenvolvedor"
        ) {
          return true;
        }

        if (this.user.id == this.modelo.id_usuario) {
          return true;
        }

        return false;
      }

      return false;
    },
    /**
     * @description Está função tem como objetivo validar por meio das regras de negócio se o usuário está visualizando, editando ou criando para exibir os ícones corretos.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    showStatusOfAction() {
      const validateBusinessRules = this.validateBusinessRules;
      const routeParamAction = this.action;

      if (routeParamAction == "criar") {
        return routeParamAction;
      }

      if (routeParamAction == "editar" && validateBusinessRules) {
        return routeParamAction;
      }

      return "visualizar";
    },
  },
  methods: {
    save(optionParam = String) {
      this.$validator.validate("basic-informations.*").then((result) => {
        if (result === true) {
          const isEdit = this.modelo.id && this.modelo.uuid ? true : false;
          const item = this.modelo;

          item.url = isEdit ? `/pco-modelos/${item.uuid}` : "/pco-modelos";
          item._method = isEdit ? "PUT" : "POST";

          this.loadings.loadingBasicInformations = true;

          this.$store
            .dispatch("pcoModelos/save", item)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: "Modelo salvo com sucesso.",
                  cor: "green",
                });

                isEdit
                  ? Object.assign(this.modelo, item)
                  : Object.assign(this.modelo, response.data);

                /**
                 * @description Objeto de opções para o salvar do Modelo. Aqui pode ser ditada vários tipos de opções que poderão ser utilizadas para uma finalidade desejada
                 * - Obrigatório o uso do parâmetro **vm** nas funções da propriedade action
                 *
                 * @property nextStep Avança um step
                 */
                const options = {
                  nextStep: {
                    value: true,
                    action: function (vm) {
                      vm.step = vm.step + 1;
                    },
                  },
                };

                options[optionParam].value
                  ? options[optionParam].action(this)
                  : null;
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: "Houve um problema em salvar o modelo.",
                cor: "red",
              });
            })
            .finally(() => {
              this.loadings.loadingBasicInformations = false;
            });
        }
      });
    },
    /**
     * @description Faz uma validação utilizando o Validator no step e retorna se está com campos faltando preenchimento ou não
     *
     * @return True Se retornado true significa que está tudo completo.
     * @return False Se retornado false significa que tem algo incompleto.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async validateStepInformations() {
      return await this.$validator.validateAll();
    },
    /**
     * @description Função responsavel por voltar o step. Antes de acontecer o retorno no step será verificado na regra é necessario ter a validação para seu retorno.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async backStep() {
      let returnFunction = true;

      // Regras de negócio antes do step retroceder
      if (this.currentPositionInSteps.role.validateToGoBack) {
        returnFunction = await this.validateStepInformations();
      }
      // -----------------------------------------

      returnFunction === true ? this.step-- : null;
    },
    /**
     * @description Função responsavel por prosseguir o step. Antes de acontecer o prosseguimento no step será verificado na regra é necessario ter a validação para seu avanço.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async nextStep() {
      let continueFunction = true;

      if (this.action !== "visualizar") {
        // Regras de negócio antes do step continuar
        if (this.currentPositionInSteps.role.validateToProceed) {
          continueFunction = await this.validateStepInformations();
        }
        if (this.currentPositionInSteps.role.saveModelByProcess) {
          return this.save("nextStep");
        }
        // -----------------------------------------
      }

      continueFunction === true ? this.step++ : null;
    },
    /**
     * @description Busca pelas informações do modelo e perguntas de forma assíncrona
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    async fetchDatasToForms() {
      // const dataEscalasByColumn = {
      //   columns: ["id", "titulo", "sigla"],
      //   where: [
      //     ["status", "A"],
      //     ["padrao", "legiio"],
      //   ],
      //   orWhere: [
      //     ["status", "A"],
      //     ["padrao", "personalizado"],
      //     ["id_empresa", this.user.id_empresa],
      //   ],
      // };

      if (
        this.user.role == "Desenvolvedor" ||
        this.user.role == "Administrador"
      )
        this.$store.dispatch("usuarios/buscarGestores");

      // this.$store.dispatch("escalas/getDataByColumn", dataEscalasByColumn);

      if (this.action == "criar") return;

      await this.$store.dispatch(
        "pcoModelos/fetchBasicInformations",
        this.uuid
      );

      const arrayPerguntas = [
        {
          categoria: "FE",
          subcategoria: null,
        },
        {
          categoria: "FI",
          subcategoria: "FAV",
        },
        {
          categoria: "FI",
          subcategoria: "BEN",
        },
        {
          categoria: "FI",
          subcategoria: "DES",
        },
        {
          categoria: "FI",
          subcategoria: "MOT",
        },
      ];
      const dataPergunta = {
        id_modelo: this.modelo.id,
        categoria: null,
        subcategoria: null,
        columns: null,
      };

      for (let typePergunta of arrayPerguntas) {
        dataPergunta.categoria = typePergunta.categoria;
        dataPergunta.subcategoria = typePergunta.subcategoria;
        dataPergunta.columns = typePergunta.columns;

        const loadingSelected = typePergunta.subcategoria
          ? typePergunta.subcategoria
          : typePergunta.categoria;

        this.$store.dispatch("pcoModelos/setLoadings", {
          property: `loading${loadingSelected}`,
          value: true,
        });

        await this.$store.dispatch(
          "pcoPerguntas/fetchQuestionsOfModelo",
          dataPergunta
        );

        this.$store.dispatch("pcoModelos/setLoadings", {
          property: `loading${loadingSelected}`,
          value: false,
        });
      }
    },
    redirect(name = String) {
      this.$store.dispatch("pcoModelos/resetModelo");
      this.$store.dispatch("pcoModelos/setStep", 1);
      this.$store.dispatch("pcoPerguntas/resetPerguntas");

      this.$router.push({ name: name });
    },
  },
};
</script>

<style scoped>
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.height-personalizado-card {
  max-height: 300px;
}

.h-auto-custom {
  height: auto;
}
</style>
<style>
.v-stepper__label {
  text-align: center !important;
}
</style>
