<template>
  <div>
    <v-dialog
      v-model="dialogDeleteElement"
      :persistent="true"
      max-width="300"
    >
      <div class="pa-2">
        <v-card>
          <v-card-title class="headline">
            <slot name="modalDelete/titleModal"></slot>
          </v-card-title>
          <v-card-text>
            <slot name="modalDelete/contentModal"></slot>
          </v-card-text>
          <v-card-actions class="justify-center">
            <slot name="modalDelete/actionsModal"></slot>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
 
<script>
export default {
  name: "ModalDelete",
  props: [
    "dialogDelete",
  ],
  computed: {
    dialogDeleteElement: {
      get() {
        return this.dialogDelete;
      },
      set(event) {
        this.$emit("update:dialogDelete", event);
      },
    },
  },
};
</script>
 
<style lang="scss">
  @import '@core/preset/preset/apps/user.scss';
</style>

