<template>
  <v-dialog v-model="loading" persistent width="350">
    <v-card elevation="2" outlined class="pa-1" :color="color" dark>
      <v-card-text>
        <slot name="content-description"></slot>
        <v-progress-linear
          indeterminate
          :color="loadingColor"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogLoaderComponent",
  props: {
    loading: {
      require: true,
      default: false,
    },
    color: {
      require: false,
      default: "primary",
    },
    loadingColor: {
      require: false,
      default: "white",
    },
  },
};
</script>

<style></style>
