import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-1",staticStyle:{"background-color":"white !important"},attrs:{"elevation":"2","max-height":"400"}},[_c(VCardTitle,{staticClass:"white--text primary",staticStyle:{"color":"white !important"}},[_c('span',[_vm._v("Lista de perguntas")]),(
        _vm.loadings[
          _vm.currentPositionInSteps.subcategory
            ? 'loading' + _vm.currentPositionInSteps.subcategory
            : 'loading' + _vm.currentPositionInSteps.type
        ]
      )?_c(VProgressCircular,{staticClass:"ml-4",attrs:{"width":"3","indeterminate":""}}):_vm._e(),_c(VSpacer),(!_vm.validateDisabledButtonAddQuestion)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"text--primary",attrs:{"color":"white","fab":"","small":""},on:{"click":function($event){_vm.dialogModalPergunta = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,2301881867)},[_c('span',[_vm._v("Adicionar pergunta")])]):_vm._e()],1),_c('div',{staticClass:"overflow-y-auto my-3 height-personalizado-card"},[_c('ItemListaPerguntaComponent',{attrs:{"validateBusinessRules":_vm.validateBusinessRules}}),(_vm.perguntasSelecionada.length <= 0)?_c('h3',{staticClass:"text-center font-weight-medium"},[_vm._v(" Não há nenhuma pergunta cadastrada. ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }