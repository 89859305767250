<template>
  <v-card
    elevation="2"
    style="background-color: white !important"
    class="mx-1"
    max-height="400"
  >
    <v-card-title class="white--text primary" style="color: white !important">
      <span>Lista de perguntas</span>
      <v-progress-circular
        v-if="
          loadings[
            currentPositionInSteps.subcategory
              ? 'loading' + currentPositionInSteps.subcategory
              : 'loading' + currentPositionInSteps.type
          ]
        "
        class="ml-4"
        width="3"
        indeterminate
      />
      <v-spacer></v-spacer>
      <v-tooltip v-if="!validateDisabledButtonAddQuestion" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="dialogModalPergunta = true"
            v-bind="attrs"
            v-on="on"
            color="white"
            class="text--primary"
            fab
            small
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Adicionar pergunta</span>
      </v-tooltip>
    </v-card-title>

    <div class="overflow-y-auto my-3 height-personalizado-card">
      <ItemListaPerguntaComponent
        :validateBusinessRules="validateBusinessRules"
      />
      <h3
        v-if="perguntasSelecionada.length <= 0"
        class="text-center font-weight-medium"
      >
        Não há nenhuma pergunta cadastrada.
      </h3>
    </div>
  </v-card>
</template>

<script>
import ItemListaPerguntaComponent from "./itemListaPerguntaComponent";

export default {
  name: "listaPerguntaComponent",
  components: {
    ItemListaPerguntaComponent,
  },
  props: {
    validateBusinessRules: {
      require: false,
      default: true,
    },
  },
  computed: {
    perguntas: {
      get() {
        return this.$store.getters["pcoPerguntas/getAllPerguntas"];
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    currentPositionInSteps: {
      get() {
        return this.$store.getters["pcoModelos/getCurrentPositionInSteps"];
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
    perguntasSelecionada() {
      const type = this.currentPositionInSteps.type;
      const subcategory = this.currentPositionInSteps.subcategory;

      return this.perguntas[type] && this.perguntas[type][subcategory]
        ? this.perguntas[type][subcategory]
        : this.perguntas[type];
    },
    /**
     * @description Valida as regras de negócio sobre o botão de criar mais perguntas. Caso não esteja permitido ele retornará True senão False será retornado.
     *
     * @dependency prop validateBusinessRules
     * @dependency prop item
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateDisabledButtonAddQuestion() {
      const validateBusinessRules = this.validateBusinessRules;
      const subcategory = this.currentPositionInSteps.subcategory;

      if (!validateBusinessRules) {
        return true;
      }

      if (this.perguntasSelecionada.length > 0) {
        if (
          subcategory == "BEN" ||
          subcategory == "MOT" ||
          subcategory == "DES"
        ) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>

<style scoped>
.height-personalizado-card {
  max-height: 300px;
}
</style>
